export default {
  click_toggle_term: {
    Aku_element_id: 'openpay030116',
    Aku_element_name: 'check agreement',
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm ',
    Aku_position_id: 16,
  },
  click_service_term: {
    Aku_element_id: 'openpay030117',
    Aku_element_name: 'transaction agreement',
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm ',
    Aku_position_id: 17,
  },
  click_password_term: {
    Aku_element_id: 'openpay030118',
    Aku_element_name: 'non-secret agreement',
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm ',
    Aku_position_id: 18,
  },
  pop_view: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_pop_id: 'pop90026',
    Aku_pop_name: 'agreement',
  },
  click_agree: {
    Aku_element_id: 'pop9002601',
    Aku_element_name: 'agree',
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_pop_id: 'pop90026',
    Aku_pop_name: 'agreement',
  },
  click_disagree: {
    Aku_element_id: 'pop9002602',
    Aku_element_name: 'disagree',
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_pop_id: 'pop90026',
    Aku_pop_name: 'agreement',
  },
}
