import { useBasicContext } from '@common/Basic'
import { Button } from 'antd-mobile'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { history } from 'umi'
import BizTracker from '~/common/tracker/bizTracker'
import Logger from '~/config/logger'
import { Translate } from '~/config/translate'
import loggerConfig from '~/views/orderDetails/logger.config'
// import { actionCreator } from '~/views/orderDetails/store'
import LicensePop from '@/components/LicensePop'
import termsConfig from '@/components/LicensePop/sa.config'
import PromotionTag from '@/project/openPay @/components/PromotionTag'
import styles from './index.scss'

interface Props {
  onClick: () => void
  pointsInfo?: any
  clickServiceLicense: () => void
  activityInfo: any
}

export const PayButton: FC<Props> = (props) => {
  const { pointsInfo, clickServiceLicense, activityInfo } = props
  const saLogger = Logger.getInstance()
  const { translate } = Translate.getInstance()
  const { currencyCode, formatPrice } = useBasicContext()
  // const dispatch = useDispatch()
  const {
    downPayment,
    showSmallPayController,
    // isSmallPay,
    orderButtonDisabled,
    hideTermsOptIn,
  } = useSelector((state) => {
    const showSmallPayController = state.getIn([
      'orderDetail',
      'showSmallPayController',
    ])
    const hideTermsOptIn = state.getIn([
      'orderDetail',
      'commonInfo',
      'hideTermsOptIn',
    ])

    return {
      // dev 模式用 显示小额支付控制器
      // showSmallPayController: true,
      hideTermsOptIn,
      showSmallPayController,
      // 调试用 大额时样式展示
      // downPayment: 13499936300,
      downPayment: state.getIn(['orderDetail', 'downPayment']),
      isSmallPay: state.getIn(['orderDetail', 'isSmallPay']),
      orderButtonDisabled: state.getIn(['orderDetail', 'orderButtonDisabled']),
    }
  })

  const [agree, setAgree] = useState(hideTermsOptIn)

  useEffect(() => {
    setAgree(hideTermsOptIn)
  }, [hideTermsOptIn])

  // function toogleIsSmallPay() {
  //   dispatch(actionCreator.goSetIsSmallPay(!isSmallPay))
  //   // 勾选时的埋点
  //   if (!isSmallPay) {
  //     saLogger.onPageClick(loggerConfig.agreement_openpay030112)
  //   }
  // }

  useEffect(() => {
    if (pointsInfo.get('isShow') > 0) {
      saLogger.expose(loggerConfig.pay_button_point_view)
    }
  }, [pointsInfo, saLogger])
  //region 小额免密曝光埋点
  useEffect(() => {
    if (showSmallPayController) {
      saLogger.onPageView(loggerConfig.agreement_openpay030112)
    }
  }, [saLogger, showSmallPayController])
  //endregion

  const handleClickPay = () => {
    if (agree) {
      props.onClick()
    } else {
      LicensePop.show({
        isSmallPay: showSmallPayController,
        onOk: () => {
          setAgree(true)
          props.onClick()
        },
        clickServiceTerm: () => {
          clickServiceLicense()
        },
        clickPasswordTerm: () => {
          history.push({
            pathname: './terms/quickPay',
            search: window.location.search,
          })
        },
      })
    }
  }

  return (
    <div className={styles.payButtonContainer}>
      <div className={styles.smallPayContainer}>
        <img
          src={
            agree
              ? require('./images/checked.png')
              : require('./images/unchecked.png')
          }
          onClick={() => {
            saLogger.click(termsConfig.click_toggle_term)
            setAgree(!agree)
          }}
        />
        {translate('I have read and agree to the')}{' '}
        <i
          onClick={() => {
            saLogger.click(termsConfig.click_service_term)
            clickServiceLicense()
          }}
        >
          {translate('Akulaku Finance Indonesia Installment Contract')}
        </i>{' '}
        {showSmallPayController && (
          <>
            {translate('and')}{' '}
            <i
              onClick={() => {
                saLogger.click(termsConfig.click_password_term)
                BizTracker.click({ cn: 7 })
                // 产品（亨菲）说只做印尼文 没有其他国家
                location.href =
                  'https://www.akulaku.com/artikel/syarat-dan-ketentuan/' // passwordfree_Agreement
                // history.push({
                //   pathname: './terms/quickPay',
                //   search: window.location.search,
                // })
              }}
            >
              {translate('password-free login policy')}
            </i>
          </>
        )}
      </div>

      <div className={styles.btnRow}>
        {/* downPayment 可能为字符串的 '0' */}
        {!!+downPayment && (
          <div className={`font-roboto-bold ${styles.downPayment}`}>
            <span>{translate('首付')}</span>
            <strong className="font-roboto-medium">{`${currencyCode}${formatPrice(
              downPayment ?? '',
            )}`}</strong>
          </div>
        )}
        <Button
          type="warning"
          className={`font-roboto-medium ${styles.btn}`}
          onClick={handleClickPay}
          // loading={orderButtonDisabled}
        >
          <div className={'flex flex-col items-center justify-center h-full'}>
            {pointsInfo.get('isShow') > 0 ? (
              <div className={styles.points}>
                <img src={require('./images/pointsIcon.png')} />
                <span>
                  {translate('奖励积分${point}', {
                    point: pointsInfo.get('pointsNum'),
                  })}
                </span>
              </div>
            ) : null}
            <div>{translate('确认支付')}</div>
          </div>
          <PromotionTag>{activityInfo?.activityTitle}</PromotionTag>
        </Button>
      </div>
    </div>
  )
}
