import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Prompt, withRouter } from 'react-router-dom'
import { Modal } from 'antd-mobile'
import _ from 'lodash'
import { globalActionCreator } from '~/common/globalActions'
import {
  PHONE_COUNTRY_CODE_MAP,
  SECURITY_VERIFICATION_BIZ_TYPE,
  SCENARIO_NUMBER_MAP,
  LOGIN_METHOD,
  SLIDER_CONFIG,
  FACE_CONFIG,
} from '~/common/macro'
import { actionCreator } from './store'
import { helpers, withBasic } from '@common'
import { store } from '../../store'
import Logger from '../../config/logger'
import { Translate } from '../../config/translate'

import styles from './style.m.scss'

import LoginPasswordForm from './components/LoginPasswordForm'
import PromotionCodeForm from './components/PromotionCodeForm'

import loggerConfig from './logger.config'
import QuickPaymentInstruction from './components/QuickPaymentInstruction'
import QuickPaymentReminder from './components/QuickPaymentReminder'
import {
  getCommonParams,
  getSecurityCacheData,
} from '@/components/Security/utils'
import { actionCreator as loginActionCreator } from '../login/store'
import BizTracker from '~/common/tracker/bizTracker'
import { getApiBaseUrl } from '~/config/utils'
import { getSmDeviceData, ALDeviceSDKInstance } from '@/components/device-sdk'
import { dynamic, history } from 'umi'
import { SecurityVerificationMobile as SecurityVerification } from '@/components/SecurityVerification'
import { PayVerification } from '~/components/PayVerification'
import { Header } from '~/components/Header'
import { NoticeCard } from '~/components/NoticeCard'
import { InstallmentPlan } from '~/components/installmentPlan'
import { setDownpaymentRate } from '~/views/orderDetails/store/actionCreator'
import { CouponCard } from '~/views/orderDetails/components/CouponCard'
import { Footer } from '~/components/Footer'

const PaymentDetails = dynamic(() => import('./components/PaymentDetails'))

import { RepaymentDateList } from './components/RepaymentDateList'
import { PayButton } from '~/views/orderDetails/components/PayButton'
import { resetIsFirstGetOrderInfo } from '~/views/orderDetails/isFirstGetOrderInfo'

import { bridge } from '~/common/bridge'
import withPerfCollect from '@/components/withPerfCollect'
import { resetIsLocationUploaded } from '~/views/orderDetails/isLocationUploaded'

const CouponListContainer = dynamic(() =>
  import('./components/CouponListContainer'),
)

const ServiceAgreementIframe = dynamic(() =>
  import('./components/ServiceAgreementIframe').then(
    (res) => res.ServiceAgreementIframe,
  ),
)
const { storage, URL, getBizEnv } = helpers
const className = helpers.classNames.react(styles)
@withBasic
class OrderDetails extends PureComponent {
  constructor() {
    super()
    this.saLogger = Logger.getInstance()
  }

  // 获取使用优惠券之后的优惠金额
  getDiscountPrice = (coupons) => {
    if (coupons) {
      const discountAmount = coupons.get('discountAmount')
      const discountOfTotalPrice = coupons.get('discountOfTotalPrice')
      return { discountOfTotalPrice, discountAmount }
    }
    return {}
  }

  handleConfirmPay = () => {
    const {
      curPeriod,
      isSmallPay,
      firstNonPasswordPay,
      toggleQuickPaymentReminderVisible,
      opreateBeforePlaceOrder,
      quickPaymentReminderHasOpen,
      curPaymentStrategy,
    } = this.props

    //region v4埋点
    // 当前选中的相关信息
    const item = _.find(
      curPaymentStrategy,
      (item) => String(item.periods) === String(curPeriod),
    )
    if (item) {
      BizTracker.click({
        cn: 6,
        ext: {
          // "product_id：产品id
          // installment_terms：分期期数
          // installment_amount：分期金额，每月还款金额
          product_id: item.fullProductId,
          installment_terms: item.periods,
          installment_amount: item.monthlyPay,
        },
      })
    }
    //endregion
    if (isSmallPay && firstNonPasswordPay && !quickPaymentReminderHasOpen) {
      // 只有第一次使用小额免密的时候才需要弹小额免密的提示框
      toggleQuickPaymentReminderVisible(true)
    } else {
      opreateBeforePlaceOrder(curPeriod)
    }
  }

  choosePeriod = (periodStr, item) => {
    const { monthlyPay, downPayment, lastMonthlyPay, downPaymentRate } = item
    const { dispatch, curPaymentStrategy } = this.props
    /**
     * 第二个参数 在之前版本是 当前分期比例下的 期数
     * 在首付比例需求优化后，使用显示在界面上的所有期数代替这个参数
     * 使得可以更改少量代码
     */
    dispatch(setDownpaymentRate(downPaymentRate, curPaymentStrategy))

    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.choose_period,
      ALpayOrderID: this.props.orderId,
    })
    BizTracker.click({
      cn: 5,
      ext: {
        // "product_id：产品id
        // installment_terms：分期期数
        // installment_amount：分期金额，每月还款金额
        product_id: item.fullProductId,
        installment_terms: item.periods,
        installment_amount: item.monthlyPay,
      },
    })
    dispatch(
      actionCreator.doChoosePeriodLogic(
        periodStr,
        monthlyPay,
        downPayment,
        lastMonthlyPay,
      ),
    )
  }

  render() {
    const {
      translate,
      closePopup,
      opreateBeforePlaceOrder,
      loginBeforePlaceOrder,
      securityVerificationOnSuccess,
      securityVerificationOnRejected,
      securityVerificationOnClose,
      coupons,
      curPeriod,
      plans,
      curMonthlyPay,
      countryId,
      languageCode,
      downPayment,
      basicPopup,
      curPaymentStrategy,
      periodOfInterestFree,
      showFullpayment,
      securityVerificationVisible,
      showSmallPayController,
      isSmallPay,
      paymentPasswordVerificationSuccess,
      paymentPasswordVerificationFail,
      paymentPasswordVerificationClose,
      paymentPasswordReportDeviceData,
      paymentPasswordGetSmDeviceData,
      showPayVerificationModal,
      clickPayTime,
      payPwdBusinessId,
      notice,
      promotionText,
      pointsInfo,
      exchangeRateVO,
      merchantCouponVOS,
      merchantName,
      merchantOriginPrice,
      activityInfo,
    } = this.props
    // 使用优惠券之后的折扣
    const { discountAmount } = this.getDiscountPrice(coupons)
    const totalPayAmount = plans.get('totalPayAmount')

    const saLogger = Logger.getInstance()
    const securityCacheParams = getSecurityCacheData()

    const loginType = URL.getParam('loginType') || securityCacheParams.loginType
    const loginTime = URL.getParam('loginTime') || securityCacheParams.loginTime
    const riskSign = URL.getParam('riskSign') || securityCacheParams.riskSign

    const shouldHideHeader =
      storage.getSession('tokenLogin') ||
      bridge.isAkulaku ||
      URL.getParam('isAlipay') === '1'

    return [
      <div className={styles.container} key={'body'}>
        <div className={styles.scrollBody}>
          <Header
            className={shouldHideHeader ? 'hidden' : ''}
            onLogoutBtnClick={() => {
              saLogger.onPageClick(loggerConfig.return)
              BizTracker.click({ cn: 8 })
            }}
            onLogoutConfirm={() => {
              saLogger.onPageClick(loggerConfig.logout_yes)
            }}
            onLogoutCancel={() => {
              saLogger.onPageClick(loggerConfig.logout_no)
            }}
          ></Header>
          <div className={styles.containerBody}>
            {/* 优先显示警告内容 */}
            {notice ? (
              <NoticeCard type={'warning'}>{notice}</NoticeCard>
            ) : (
              <NoticeCard>{promotionText}</NoticeCard>
            )}

            <InstallmentPlan
              curPeriod={curPeriod}
              list={curPaymentStrategy}
              onCurPeriodChange={this.choosePeriod}
              payAmount={totalPayAmount}
              merchantName={merchantName}
              exchangeRateVO={exchangeRateVO}
              merchantCouponVOS={merchantCouponVOS}
              merchantOriginPrice={merchantOriginPrice}
              discountAmount={discountAmount}
              periodOfInterestFree={periodOfInterestFree}
              showFullpayment={showFullpayment}
              downPayment={downPayment}
              showDownPayment
              activityInfo={activityInfo}
              showPromotionAmount={true}
              renderAfterList={() => {
                return <RepaymentDateList />
              }}
              onExpandChange={(expand) => {
                saLogger.onPageClick(
                  expand
                    ? loggerConfig.expand_openpay030113
                    : loggerConfig.collapse_openpay030114,
                )
              }}
            ></InstallmentPlan>

            <CouponCard></CouponCard>

            <Footer className={styles.footer}>
              <p className={styles.serviceBanks}>
                {translate(
                  `由Bank Neo Commerce、Bank Ganesha、Bank Shinhan Indonesia、Bank OCBC NISP、BPR Supra Artapersada、Bank CTBC Indonesia、Bank Permata等银行提供信用贷款服务`,
                )}
              </p>
              {/* <p className={styles.serviceAgreement}>
                <span>{translate('同意')} </span>
                <a onClick={this.props.openAgreement}>
                  {translate('服务协议')}
                </a>
              </p>  */}
            </Footer>
          </div>
        </div>
        <PayButton
          clickServiceLicense={this.props.openAgreement}
          pointsInfo={pointsInfo}
          onClick={_.debounce(this.handleConfirmPay, 200)}
          activityInfo={activityInfo}
        ></PayButton>
      </div>,
      <PromotionCodeForm key="promotionCode" />,
      <LoginPasswordForm
        key={'loginPwdForm'}
        onSuccess={(loginPwd) => loginBeforePlaceOrder(loginPwd, curPeriod)}
      />,
      <Modal
        popup
        visible={basicPopup.get('show')}
        onClose={closePopup}
        animationType="slide-up"
        maskClosable={true}
        key={'popup'}
      >
        {this.makePopupContent()}
      </Modal>,
      showPayVerificationModal ? (
        <PayVerification
          locale={languageCode ? languageCode.toLowerCase() : 'in'}
          env={getBizEnv()}
          apiBaseURL={getApiBaseUrl('/capi')}
          countryId={countryId}
          bizType={SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER}
          saParams={{
            pageId: 'openpay03',
            pageName: 'openpay confirm order page',
          }}
          onSuccess={paymentPasswordVerificationSuccess}
          onClose={paymentPasswordVerificationClose}
          onRequestError={paymentPasswordVerificationFail}
          reportDeviceData={paymentPasswordReportDeviceData}
          getSmDeviceData={paymentPasswordGetSmDeviceData}
        />
      ) : null,
      securityVerificationVisible ? (
        <SecurityVerification
          hideInitialLoading={true}
          visible={securityVerificationVisible}
          key={'SecurityVerification'}
          locale={languageCode ? languageCode.toLowerCase() : 'in'}
          languageCode={languageCode ? languageCode.toLowerCase() : 'in'}
          env={getBizEnv()}
          bizType={
            bridge.isAkulaku
              ? SECURITY_VERIFICATION_BIZ_TYPE.APP_ADD_ORDER
              : SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER
          }
          deviceId={this.props.device_id}
          deviceUuid={helpers.storage.getSession('deviceData')?.uuid}
          extraParams={{
            ...getCommonParams(),
            amount: totalPayAmount,
            isSecretPay: showSmallPayController && isSmallPay ? 0 : 1,
            orderId: this.props.orderId,
            device_id: this.props.device_id,
            order_credit: curMonthlyPay * curPeriod,
            clickPayTime,
            downPayment,
            payPwdBusinessId: payPwdBusinessId || null,
            fullProductId: helpers.storage.getSession('full_product_id'),
            loginType,
            loginTime,
            riskSign,
            acquiringType: URL.getParam('acquiringType'),
          }}
          userId={helpers.storage.getSession('openpay_uid')}
          appBrand="op"
          saParams={{
            pageId: 'openpay03',
            pageName: 'openpay confirm order page',
          }}
          countryCode={`+${PHONE_COUNTRY_CODE_MAP[countryId]}`}
          phoneNumber={this.phoneDesensitization()}
          onSuccess={securityVerificationOnSuccess}
          onRejected={securityVerificationOnRejected}
          onClose={securityVerificationOnClose}
          apiBaseURL={getApiBaseUrl('/capi')}
          onRequestError={this.handleSecurityVerificationRequestError}
          reportDeviceData={{
            pin: paymentPasswordReportDeviceData,
          }}
          getSmDeviceData={paymentPasswordGetSmDeviceData}
          sliderData={SLIDER_CONFIG}
          faceOptions={FACE_CONFIG}
        />
      ) : null,
      <QuickPaymentInstruction key="quickPaymentInstruction" />,
      <QuickPaymentReminder
        key="quickPaymentReminder"
        onOk={_.debounce(() => opreateBeforePlaceOrder(curPeriod), 200)}
      />,
      // 防止用户在akuapp内无法点击返回回到首页
      bridge.isAkulaku && (
        <Prompt
          key={'akuapp.prompt'}
          when={true}
          message={(location, action) => {
            if (action !== 'POP') {
              return true
            }
            bridge.jumpPaylaterTab()
          }}
        ></Prompt>
      ),
    ]
  }

  // 安全组件请求报错 errorInfo 有两种情况 一种是接口的response 一种是Error 实例
  handleSecurityVerificationRequestError = ({ error, type }) => {
    const { securityVerificationLogout, securityVerificationOnClose } =
      this.props
    this.saLogger.click({
      ...loggerConfig.confirm_payment,
      code: error.errCode,
      Aku_msg: error.errMsg,
    })
    if (error.errCode === '401') {
      securityVerificationOnClose()
      securityVerificationLogout()
    }
  }

  // 手机号脱敏 手机号从url获取 如果获取不到就从localStorage 拿
  phoneDesensitization = () => {
    const phone = (URL.allParam || {}).phone || storage.get('phoneNumber')
    return phone ? `${phone.slice(0, 2)} **** ${phone.slice(-4)}` : phone
  }

  makePopupContent = (discountDPprice, discountDPrate, hasDPdiscount) => {
    const { basicPopup, closePopup, countryCode, languageCode } = this.props
    const popupName = basicPopup.get('name')

    if (popupName === 'planDetails') {
      return <PaymentDetails />
    } else if (popupName === 'couponList') {
      return <CouponListContainer />
    } else if (popupName === 'ServiceAgreementIframe') {
      return (
        <ServiceAgreementIframe
          countryCode={countryCode}
          languageCode={languageCode}
          closePopup={closePopup}
        />
      )
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { basicDialog } = this.props
    const dialogType = nextProps.basicDialog.get('dialogType')
    const dialogContent = nextProps.basicDialog.get('content')
    const shouldShowDialog =
      !basicDialog.get('show') && nextProps.basicDialog.get('show')

    shouldShowDialog && this.controlGlobalDialog(dialogType, dialogContent)
  }

  controlGlobalDialog(dialogType, dialogContent) {
    const { showFullPayDialog, translate, currencyCode } = this.props

    //如果弹窗类型是设置全款
    if (dialogType === 'setFullPay') {
      showFullPayDialog(dialogContent)
    } else if (dialogType === 'priceDetail') {
      const {
        originPrice,
        plans,
        curPeriod,
        formatPrice,
        showPriceDetailPopup,
      } = this.props
      const discountPrice = plans.getIn(['discountPriceOfPeriods', curPeriod])
      const originalPrice = plans.get('price')

      const content = (
        <div {...className('price-detial-tips font-lato-reg')}>
          <p {...className('price-detial-order-amount')}>
            <span>{translate('商品价格')}</span>
            <span>
              {currencyCode} {formatPrice(originPrice)}
            </span>
          </p>
          {discountPrice && (
            <p>
              <span>{translate('折后价格')}</span>
              <span>
                {currencyCode}
                {formatPrice(discountPrice)}
              </span>
            </p>
          )}
          <p>
            <span>{translate('交易手续费')}</span>
            <span>
              {currencyCode}
              {(originalPrice - originPrice).toFixed(2)}
            </span>
          </p>
        </div>
      )

      showPriceDetailPopup(content)
    }
  }

  getAndReportConfig = async () => {
    const methodToken = URL.getParam('loginMethodToken')

    const securityCacheParams = getSecurityCacheData()
    const loginType = URL.getParam('loginType') || securityCacheParams.loginType

    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(methodToken),
    )
    this.saLogger.onPageView({
      ...loggerConfig.page_view,
      refNo: this.props.refNo,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      // 后端解析methodToken有问题，密码登录时返回的akuChannelName是3，密码登录时从loginType拿值
      Aku_channelName: LOGIN_METHOD[loginType],
      appName: data?.appName,
    })
  }

  componentDidMount = async () => {
    resetIsFirstGetOrderInfo()
    resetIsLocationUploaded()
    const {
      getOrderInfo,
      history,
      saveEnterTime,
      saveOrderParam,
      countryCode,
      getPublicConfig,
      handleGeneratePaymentID,
    } = this.props
    const { state } = history.location
    BizTracker.enter({ sn: 390004 })
    saveEnterTime()
    // 登录页面从url取的分期数通过路由参数传递
    let curPeriod = state ? state.curPeriod : null
    // 由于放量老系统跳转新系统路由参数丢失，需要重新从url取分期数
    if (!curPeriod) {
      curPeriod = helpers.URL.getParam('periods')
    }
    getPublicConfig()
    await getOrderInfo(curPeriod ? curPeriod : null)
    handleGeneratePaymentID() // 生成支付ID
    saveOrderParam(curPeriod ? curPeriod : null, countryCode)
    this.getAndReportConfig()
  }

  componentWillUnmount() {
    BizTracker.leave()
  }
}

const mapStateToProps = (state) => ({
  refNo: state.getIn(['main', 'refNo']),
  plans: state.getIn(['orderDetail', 'plans']),
  curPaymentStrategy:
    state.getIn(['orderDetail', 'curPaymentStrategy'])?.toJS() ?? [],
  curPeriod: state.getIn(['orderDetail', 'curPeriod']),
  curMonthlyPay: state.getIn(['orderDetail', 'curMonthlyPay']),
  downpaymentRate: state.getIn(['orderDetail', 'downpaymentRate']),
  downPayment: state.getIn(['orderDetail', 'downPayment']),
  basicDialog: state.getIn(['orderDetail', 'basicDialog']),
  basicPopup: state.getIn(['orderDetail', 'basicPopup']),
  operationId: state.getIn(['orderDetail', 'operationId']),
  pageId: state.getIn(['orderDetail', 'pageId']),
  periodOfInterestFree: state.getIn(['orderDetail', 'periodOfInterestFree']),
  originPrice: state.getIn(['orderDetail', 'originPrice']), //商品真实价格。页面显示价格是在真实价格上向上取整
  showFullpayment: state.getIn(['orderDetail', 'showFullpayment']),
  coupons: state.getIn(['orderDetail', 'coupons']),
  securityVerificationVisible: state.getIn([
    'orderDetail',
    'securityVerificationVisible',
  ]),
  needSecurityVerification: state.getIn(
    'orderDetail',
    'needSecurityVerification',
  ),
  showSmallPayController: state.getIn([
    'orderDetail',
    'showSmallPayController',
  ]),
  isSmallPay: state.getIn(['orderDetail', 'isSmallPay']),
  firstNonPasswordPay: state.getIn(['orderDetail', 'firstNonPasswordPay']),
  quickPaymentReminderHasOpen: state.getIn([
    'orderDetail',
    'quickPaymentReminderHasOpen',
  ]),
  orderId: state.getIn(['orderDetail', 'orderId']),
  device_id: storage.get('deviceId') || 'unknown',
  showPayVerificationModal: state.getIn([
    'orderDetail',
    'showPayVerificationModal',
  ]),
  clickPayTime: state.getIn(['orderDetail', 'clickPayTime']),
  payPwdBusinessId: state.getIn(['orderDetail', 'paymentPasswordOperationId']),
  notice: state.getIn(['orderDetail', 'commonInfo', 'notice']),
  promotionText: state.getIn(['orderDetail', 'commonInfo', 'promotionText']),
  installmentEndText: state.getIn([
    'orderDetail',
    'commonInfo',
    'installmentEndText',
  ]),
  pointsInfo: state.getIn(['orderDetail', 'pointsInfo']),
  exchangeRateVO: state.getIn(['orderDetail', 'exchangeRateVO']),
  merchantCouponVOS: state.getIn(['orderDetail', 'merchantCouponVOS']),
  merchantName: state.getIn(['orderDetail', 'merchantName']),
  merchantOriginPrice: state.getIn(['orderDetail', 'merchantOriginPrice']),
  activityInfo: state.getIn(['orderDetail', 'activityInfo'])?.toJS(),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  saveOrderParam(curPeriod, countryCode) {
    dispatch(actionCreator.goSaveOrderParam(curPeriod, countryCode))
  },
  getOrderInfo(curPeriod) {
    // 如果进入订单页设备sdk还没初始化，再获取一次设备信息
    if (!ALDeviceSDKInstance) {
      dispatch(globalActionCreator.globalAsyncGetDeviceData())
    }
    return dispatch(actionCreator.goGetOrderInfo(curPeriod))
  },
  showFullPayDialog(content) {
    const translate = Translate.getInstance().translate

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: content ? translate('分期失败') : translate('提示'), //TOFIX: 临时这样写的
        content: content
          ? content
          : translate(
              '基于COVID-19疫情期间您的最新信用评估，很抱歉您不能分期支付此订单，请使用全款支付。当此订单能通过分期支付时，我们会及时通知您。请注意安全和身体健康！',
            ),
        footer: [
          {
            text: translate('我知道了'),
            onPress: () => {
              dispatch(actionCreator.goChooseFullpayOnly())

              const saLogger = Logger.getInstance()
              saLogger.setOpenpayRiskStatus('全款')
            },
          },
        ],
      }),
    )
    dispatch(
      actionCreator.goToggleBasicDialog({
        show: false,
        dialogType: '',
        content: '',
      }),
    )
  },

  closePopup() {
    dispatch(actionCreator.goToggleBasicPopup(false))
  },
  //下单前置操作
  opreateBeforePlaceOrder(curPeriod) {
    const translate = Translate.getInstance().translate
    const state = store.getState()
    const isSingleFullPayment = state.getIn([
      'orderDetail',
      'plans',
      'isSingleFullPayment',
    ])
    // const skipPassword = state.getIn(['orderDetail', 'skipPassword'])
    const isSmallPay = state.getIn(['orderDetail', 'isSmallPay'])
    const saLogger = Logger.getInstance()
    const needSecurityVerification = state.getIn([
      'orderDetail',
      'needSecurityVerification',
    ]) // 接口返回的是否需要安全组件
    const needRiskChcek = needSecurityVerification && curPeriod !== '0' //全款支付不需要经过风控验证
    const autoLoginTimestampInStorage = storage.getSession('autoLoginTimestamp')
    const showSmallPayController = state.getIn([
      'orderDetail',
      'showSmallPayController',
    ])

    //#region 神策埋点
    const payments = state.getIn(['orderDetail', 'plans', 'payments'])
    const downpaymentRate = state.getIn(['orderDetail', 'downpaymentRate'])
    // 当前选中的分期信息
    const curPeriodInfo = payments
      ?.toJS()
      [downpaymentRate]?.find(
        (item) => String(item.periods) === String(curPeriod),
      )

    helpers.storage.setSession('full_product_id', curPeriodInfo?.fullProductId)
    saLogger.click({
      ...loggerConfig.confirm_payment,
      Aku_product_id: curPeriodInfo?.fullProductId,
      skucreditTerm: curPeriod,
    })
    //#endRegion

    // 上报设备指纹信息
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.ADD_ORDER,
      }),
    )

    if (curPeriod === '0' && isSingleFullPayment) {
      dispatch(
        globalActionCreator.toggleAlertDialog(true, {
          title: translate('提示'),
          content: translate('当前暂时无法提交订单，请稍后再试'),
          footer: [
            {
              text: translate('确认'),
            },
          ],
        }),
      )
      return
    }

    dispatch(
      actionCreator.goOpreateBeforePlaceOrder({
        needRiskChcek,
        isAutoLogin: !!autoLoginTimestampInStorage, // 是否是缓存自动登录，看sessionStorage 中是否有这个参数
        skipPassword: showSmallPayController && isSmallPay, // 是否跳过密码输入 由小额免密用户选择决定
      }),
    )

    // if (showSmallPayController && isSmallPay) {
    //   dispatch(actionCreator.goOpreateBeforePlaceOrder({ needRiskChcek }))
    // } else {
    //   //是否是快捷登录进来的用户
    //   if (autoLoginTimestampInStorage) {
    //     /*
    //      * 快捷支付需求,isNewPasswordProcessing为true表示该用户触发快捷支付
    //      * 通过自动登录下单的用户
    //      * 先调用风控验证接口
    //      * 1.不管风控是否需要校验，只要当天交易金额不超过150K，不需要输入密码直接下单
    //      * 2.如果风控不需要校验，且当天交易超过150K，则展示新输入密码/短信验证弹窗
    //      * 3.如果风控需要校验，则展示默认密码输入弹窗
    //      */

    //     dispatch(
    //       actionCreator.goOpreateBeforePlaceOrder({
    //         needRiskChcek,
    //         isAutoLogin: true,
    //         skipPassword,
    //       })
    //     )
    //   } else {
    //     dispatch(actionCreator.goOpreateBeforePlaceOrder({ needRiskChcek }))
    //   }
    // }
  },

  saveEnterTime() {
    dispatch(actionCreator.goSaveEnterTime(new Date().getTime()))
  },
  openAgreement(countryCode, languageCode) {
    BizTracker.click({ cn: 7 })
    const saLogger = Logger.getInstance()
    saLogger.onPageClick(loggerConfig.click_terms)
    // 20201208 协议页迁移到自己项目下
    dispatch(actionCreator.goToggleBasicPopup(true, 'ServiceAgreementIframe'))
    // const bizEnv = getBizEnv()
    // const domain = [
    //   'https://',
    //   bizEnv === 'prod' ? '' : `${bizEnv}-`,
    //   'mall.akulaku.com'
    // ].join('')
    // languageCode === 'EN'
    //   ? (window.location.href = `${domain}/v2/terms.html?lang=ph`)
    //   : (window.location.href = `${domain}/v2/terms.html?lang=${countryCode.toLowerCase()}`)
  },
  loginBeforePlaceOrder(loginPwd, curPeriod) {
    const state = store.getState()
    const needSecurityVerification = state.getIn([
      'orderDetail',
      'needSecurityVerification',
    ]) // 接口返回的是否需要安全组件
    const needRiskChcek = needSecurityVerification && curPeriod !== '0' //全款支付不需要经过风控验证

    dispatch(actionCreator.goLoginBeforePlaceOrder(loginPwd, needRiskChcek))
  },
  showPriceDetailPopup(content) {
    const translate = Translate.getInstance().translate

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: translate('价格明细'),
        content,
        footer: [
          {
            text: translate('我知道了'),
            onPress: () => null,
          },
        ],
      }),
    )
    dispatch(
      actionCreator.goToggleBasicDialog({
        show: false,
        dialogType: '',
        content: '',
      }),
    )
  },
  // 获取公共配置 暂时是只有是否需要安全组件
  getPublicConfig() {
    dispatch(actionCreator.goGetPublicConfig('mobile'))
  },
  // 安全组件校验成功
  securityVerificationOnSuccess({ operationId }) {
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_operation,
      Aku_operation_id: bridge.isAkulaku
        ? SECURITY_VERIFICATION_BIZ_TYPE.APP_ADD_ORDER
        : SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER,
      return_code: 1, // 安全组件状态：1-验证通过、2-验证失败
    })
    const isAutoLogin = storage.getSession('autoLoginTimestamp') ? true : false
    const state = store.getState()
    // 20201202 是否跳过密码 只有小额免密有关系
    const showSmallPayController = state.getIn([
      'orderDetail',
      'showSmallPayController',
    ])
    const isSmallPay = state.getIn(['orderDetail', 'isSmallPay'])
    const skipPassword = showSmallPayController && isSmallPay
    dispatch(
      actionCreator.afterSecurityVerification({
        operationId,
        isAutoLogin,
        skipPassword,
      }),
    )
  },
  // 安全组件关闭
  securityVerificationOnClose() {
    dispatch(actionCreator.toggleSecurityVerification(false))
  },
  // 安全组件拒绝
  securityVerificationOnRejected(retry, extra) {
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_payment,
      code: 'unknown',
      Aku_msg: 'security verification reject',
    })
    saLogger.click({
      ...loggerConfig.confirm_operation,
      Aku_operation_id: bridge.isAkulaku
        ? SECURITY_VERIFICATION_BIZ_TYPE.APP_ADD_ORDER
        : SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER,
      return_code: 2, // 安全组件状态：1-验证通过、2-验证失败
    })
    // 高风险订单被安全拦截，需要引导到站内支付
    if (extra?.errCode === '132132600025') {
      dispatch(actionCreator.toggleSecurityVerification(false))
      history.push({
        pathname: './security',
        search: `${window.location.search}&operationId=${extra?.operationId}`,
      })
    }
  },
  // 安全组件登录态没有退出
  securityVerificationLogout() {
    dispatch(loginActionCreator.goSetNeedLogoutToast(true))
    dispatch(globalActionCreator.smartJumpToLoginPage())
  },
  // 支付密码组件校验成功
  paymentPasswordVerificationSuccess({ businessId }) {
    dispatch(
      actionCreator.setPasswordVerificationData({
        paymentPasswordCheckPass: true,
      }),
    )
    dispatch(actionCreator.togglePaymentPasswordVerification(false))
    dispatch(actionCreator.continueRiskCheck({ operationId: businessId }))
  },
  // 支付密码组件校验失败
  paymentPasswordVerificationFail(err) {
    console.log(err)
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_payment,
      code: err?.error?.errCode,
      Aku_msg: err?.error?.errMsg,
    })
    // dispatch(globalActionCreator.toggleToast(true, err.errorMsg || err.message))
  },
  // 支付密码组件校验关闭
  paymentPasswordVerificationClose(err) {
    dispatch(actionCreator.__disableOrderButton(false))
    dispatch(actionCreator.togglePaymentPasswordVerification(false))
  },
  toggleQuickPaymentReminderVisible(quickPaymentReminderVisible) {
    dispatch(
      actionCreator.goToggleQuickPaymentReminderVisible(
        quickPaymentReminderVisible,
      ),
    )
  },
  handleGeneratePaymentID() {
    dispatch(actionCreator.generatePaymentID())
  },
  paymentPasswordReportDeviceData() {
    // pin码 支付验证上报自研设备信息
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.PAYMENT_PASSWORD,
      }),
    )
  },
  async paymentPasswordGetSmDeviceData() {
    // pin码 支付验证上报数美设备信息
    const smDeviceData = await getSmDeviceData()
    return smDeviceData
  },
})

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails)),
)
