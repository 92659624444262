import React, { PureComponent } from 'react'
import { Modal } from 'antd-mobile'
import { connect } from 'react-redux'

import { helpers, withBasic } from '@common'
import { actionCreator } from '../../store'
import { ModalTitle } from '~/components/ModalTitle'
import QuickPay from '../../../../assets/img/quickPay.png'

import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

@withBasic
class QuickPaymentInstruction extends PureComponent {
  render() {
    const {
      quickPaymentInstructionVisible,
      toggleQuickPaymentInstructionVisible,
      showSmallPayController,
      translate,
    } = this.props

    return (
      <Modal
        visible={showSmallPayController && quickPaymentInstructionVisible}
        transparent
        maskClosable={false}
        title={
          <ModalTitle
            title={<img {...className('quick_payment_img')} src={QuickPay} />}
            onClick={toggleQuickPaymentInstructionVisible}
          />
        }
        footer={[
          {
            text: translate('确认'),
            onPress: toggleQuickPaymentInstructionVisible,
          },
        ]}
        {...className('quick_payment_instruction')}
      >
        {/* 此处写两个p 是因为要一行居中 多行左对齐 */}
        <p {...className('instruction_title')}>
          <p>{translate('全新Akulaku极速支付')}</p>
        </p>
        <div {...className('instruction_item')}>
          <p {...className('item_title')}>{translate('1、安全保障')}</p>
          <p {...className('item_content')}>
            {translate('实时、动态监控每笔订单，全方位的安全防护')}
          </p>
        </div>
        <div {...className('instruction_item')}>
          <p {...className('item_title')}>{translate('2、极致体验')}</p>
          <p {...className('item_content')}>
            {translate('只需一步，即可支付')}
          </p>
        </div>
        <div {...className('instruction_item')}>
          <p {...className('item_title')}>{translate('3、灵活使用')}</p>
          <p {...className('item_content')}>
            {translate('适用于符合安全条件的订单，可随时取消')}
          </p>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  quickPaymentInstructionVisible: state.getIn([
    'orderDetail',
    'quickPaymentInstructionVisible',
  ]),
  showSmallPayController: state.getIn([
    'orderDetail',
    'showSmallPayController',
  ]),
})

const mapDispatchToProps = (dispatch) => ({
  toggleQuickPaymentInstructionVisible() {
    console.log('toggleQuickPaymentInstructionVisible')
    dispatch(actionCreator.goToggleQuickPaymentInstructionVisible(false))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuickPaymentInstruction)
