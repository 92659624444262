import React, { useMemo } from 'react'
import styles from './index.scss'
import {
  getNextRepaymentDate,
  getRepaymentDates,
} from '@common/akulaku/business/bill_date'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreator } from '~/views/orderDetails/store'
import loggerConfig from '~/views/orderDetails/logger.config'
import Logger from '~/config/logger'
import { useBasicContext } from '@common/Basic'
import { classNames } from '@common/helpers'

/**
 * 还款日选择展示
 */
export function RepaymentDateList() {
  const { translate } = useBasicContext()
  const dispatch = useDispatch()
  const { repaymentDay, userSetRepaymentDate, orderId } = useSelector(
    (state) => {
      const userSetRepaymentDate = state.getIn([
        'orderDetail',
        'userSetRepaymentDate',
      ])
      return {
        orderId: state.getIn(['orderDetail', 'orderId']),
        repaymentDay: state.getIn(['orderDetail', 'repaymentDay']),
        //设置为 false 时会展示还款日设置模块，dev debug 用
        // userSetRepaymentDate: false,
        userSetRepaymentDate,
      }
    },
  )

  const nextRepaymentDayDisplay = useMemo(() => {
    return getNextRepaymentDate(repaymentDay)
  }, [repaymentDay])

  return (
    <div className={styles.container}>
      {/* 仅对未设置还款日的用户展示，默认选中 ‘25号’ 选项； */}
      {!userSetRepaymentDate && (
        <>
          <div className={styles.selectRepaymentDateTitle}>
            {translate('选择还款日')}
          </div>
          <div className={styles.strongTips}>
            {translate('仅可以选择一次，请谨慎选择')}
          </div>

          <div className={styles.row}>
            {getRepaymentDates().map((item) => {
              return (
                <div
                  key={item.value}
                  className={classNames(
                    styles.item,
                    `font-roboto-medium`,
                    item.value === repaymentDay && styles.active,
                  )}
                  onClick={() => {
                    const saLogger = Logger.getInstance()
                    saLogger.onPageClick({
                      ...loggerConfig.comfirm_repayment_date,
                      Aku_buttonName: item.value,
                    })
                    dispatch(actionCreator.setRepaymentDay(item.value))
                  }}
                >
                  {item.label}
                </div>
              )
            })}
          </div>
        </>
      )}

      <div className={styles.tipsRow}>
        <img src={require('./images/info.png')} />
        <span>
          {translate('首次还款时间', { date: nextRepaymentDayDisplay })}
          <br />
          <a
            className={styles.detailBtn}
            onClick={() => {
              //查看分期详情
              dispatch(actionCreator.goToggleBasicPopup(true, 'planDetails'))
              const saLogger = Logger.getInstance()
              saLogger.onPageClick({
                ...loggerConfig.repayment_plan_openpay030108,
                ALpayOrderID: orderId,
              })
            }}
          >
            {translate('查看详情')}
          </a>
        </span>
      </div>
    </div>
  )
}
