import React, { PureComponent } from 'react'
import { Modal } from 'antd-mobile'
import { connect } from 'react-redux'

import { helpers, withBasic } from '@common'
import { actionCreator } from '../../store'
import { ModalTitlePop } from '~/components/ModalTitle'
import Icon from '~/components/Icon'

import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

@withBasic
class QuickPaymentReminder extends PureComponent {
  /**
   * 确定小额免密支付
   * 如果打开了密码框 关闭
   * 关闭这个提示弹窗
   * 修改 quickPaymentReminderHasOpen: true
   * */

  confirmPay = () => {
    const {
      setIsSmallPay,
      onOk,
      showLoginPasswordDialog,
      goToggleLoginPasswordDialog,
      toggleQuickPaymentRemindVisible,
    } = this.props
    setIsSmallPay(true)
    showLoginPasswordDialog && goToggleLoginPasswordDialog(false)
    toggleQuickPaymentRemindVisible()
    typeof onOk === 'function' && onOk()
  }

  render() {
    const {
      translate,
      quickPaymentReminderVisible,
      toggleQuickPaymentRemindVisible,
    } = this.props

    const reminderText = [
      {
        question: translate('什么是小额免密支付？'),
        answer: translate(
          'A：小额免密支付全称是Akulaku小额分期免登录密码验证支付，它是一种安全、便捷的支付方式，开启后，可在确认支付时无需要输入登录密码，只需要短信验证码校验即可完成支付',
        ),
      },
      {
        question: translate('小额免密支付的安全性如何？'),
        answer: translate(
          'A:非常安全。Akulaku会针对每笔订单的场景特征、订单金额、风险控制等多维度实时判断是否对该订单使用小额免密支付。',
        ),
      },
      {
        question: translate('如何开启/关闭小额免密支付？'),
        answer: translate('A:您可以在订单页面选择开启/关闭小额免密支付选项'),
      },
    ]

    return (
      <Modal
        popup
        visible={quickPaymentReminderVisible}
        animationType="slide-up"
        maskClosable={false}
        title={
          <ModalTitlePop
            borderBottom={false}
            title={translate('温馨提示')}
            onClick={toggleQuickPaymentRemindVisible}
          />
        }
        footer={[
          {
            text: translate('免密支付'),
            onPress: this.confirmPay,
          },
        ]}
        {...className('quick_payment_reminder')}
      >
        {reminderText.map((item, index) => (
          <QuestionItem key={index} {...item} />
        ))}
      </Modal>
    )
  }
}

function QuestionItem({ question, answer }) {
  return (
    <div {...className('reminder_item')}>
      <div {...className('item_question')}>
        <Icon icontype="svg" name="Q" {...className('item_question_icon')} />
        <div {...className('item_question_content')}>{question}</div>
      </div>
      <div {...className('item_answer')}>{answer}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  quickPaymentReminderVisible: state.getIn([
    'orderDetail',
    'quickPaymentReminderVisible',
  ]),
  showLoginPasswordDialog: state.getIn([
    'orderDetail',
    'showLoginPasswordDialog',
  ]),
})

const mapDispatchToProps = (dispatch) => ({
  toggleQuickPaymentRemindVisible() {
    // 关闭弹窗的时候 把quickPaymentReminderHasOpen 改为true，表示已经打开过了
    dispatch(actionCreator.goToggleQuickPaymentReminderVisible(false))
    dispatch(actionCreator.goToggleQuickPaymentReminderHasOpen(true))
  },
  setIsSmallPay(isSmallPay) {
    dispatch(actionCreator.goSetIsSmallPay(isSmallPay))
  },
  goToggleLoginPasswordDialog(bool) {
    dispatch(actionCreator.toggleLoginPasswordDialog(bool))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuickPaymentReminder)
