import { classNames } from '@/vendor/common @/helpers'
import { useBasicContext } from '@common/Basic'
import { Accordion } from 'antd-mobile'
import _ from 'lodash'
import { FC, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { COUPON_MODAL_FLAG, COUPON_TYPE } from '~/common/macro'
import BizTracker from '~/common/tracker/bizTracker'
import Logger from '~/config/logger'
import { Translate } from '~/config/translate'
import loggerConfig from '~/views/orderDetails/logger.config'
import { actionCreator } from '~/views/orderDetails/store'
import styles from './index.scss'

function useStoreState() {
  return useSelector((state) => {
    const orderDetail: any = _.result(state.getIn(['orderDetail']), 'toJS')

    /**
     * 利息券展示需要这里的数据 periodsTotalDiscount 利息券优惠金额
     */
    const curPeriodItem = _.find(
      orderDetail?.curPaymentStrategy,
      (item) => String(item.periods) === String(orderDetail?.curPeriod),
    )

    // 商户优惠券信息
    const merchantCouponVOS = _.get(orderDetail, 'merchantCouponVOS')

    const merchantName = _.get(orderDetail, 'merchantName')

    return {
      availableCouponList: _.get(
        orderDetail,
        'coupons.availableCouponList',
        [],
      ),
      discountAmount: _.get(orderDetail, 'coupons.discountAmount'),
      periodsTotalDiscount: curPeriodItem?.periodsTotalDiscount,
      curCouponId: _.get(orderDetail, 'curCouponId'),
      couponType: _.get(orderDetail, 'coupons.couponType'),
      merchantCouponVOS,
      merchantName,
    }
  }, _.isEqual)
}

export const CouponCard: FC = () => {
  const { translate } = Translate.getInstance()
  const { formatPrice, currencyCode } = useBasicContext()
  const dispatch = useDispatch()

  const {
    availableCouponList,
    curCouponId,
    discountAmount,
    periodsTotalDiscount,
    couponType,
    merchantCouponVOS,
    merchantName,
  } = useStoreState()

  function showCouponList() {
    dispatch(actionCreator.goSetCouponModalFlag(COUPON_MODAL_FLAG.COUPON))
    // 打开弹窗的时候 记录原先外面现实的优惠券id
    dispatch(actionCreator.goSetPrevCouponId(curCouponId))
    dispatch(actionCreator.goToggleBasicPopup(true, 'couponList'))
    const saLogger = Logger.getInstance()
    saLogger.onPageClick(loggerConfig.click_coupon)
    saLogger.onPageClick(loggerConfig.coupon_popup)
    BizTracker.click({ cn: 9 })
  }

  const curCoupon = useMemo(() => {
    return _.find(availableCouponList, { couponId: curCouponId })
  }, [availableCouponList, curCouponId])

  useEffect(() => {
    //region 选中优惠券埋点
    // coupon_type：优惠券类型（1-满减券，2-满折券，3-利息券）
    // coupon_amount：优惠券金额
    if (curCoupon) {
      const coupon_amount =
        curCoupon.couponType === COUPON_TYPE.COUPON_OPENPAY_INTEREST ||
        curCoupon.couponType === COUPON_TYPE.COUPON_INTEREST_RATE
          ? periodsTotalDiscount
          : discountAmount
      BizTracker.click({
        cn: 24,
        sn: 390004,
        ext: {
          coupon_type: curCoupon.couponType,
          coupon_amount,
        },
      })
      //endregion
    }
  }, [curCoupon, discountAmount, periodsTotalDiscount])

  function renderRight() {
    if (!curCoupon) {
      // 当前可用几张优惠券
      return (
        !!availableCouponList.length && (
          <span className={styles.available}>
            {`${availableCouponList.length} coupons available`}
          </span>
        )
      )
    }

    const toDisplayPrice =
      curCoupon.couponType === COUPON_TYPE.COUPON_OPENPAY_INTEREST ||
      curCoupon.couponType === COUPON_TYPE.COUPON_INTEREST_RATE
        ? periodsTotalDiscount
        : discountAmount

    const priceText = `-${currencyCode}${formatPrice(toDisplayPrice)}`
    return (
      <div className={classNames(styles.common, styles.nowrap)}>
        <span className={`font-roboto-medium ${styles.available}`}>
          {priceText}
        </span>
        {/* <span className={styles.couponName}>{curCoupon.name || 'AAA'}</span> */}
      </div>
    )
  }

  // 商户优惠金额
  const marchantCouponPrice = _.reduce(
    merchantCouponVOS,
    (pre, cur) => pre + Number(_.get(cur, 'amount.value', 0)),
    0,
  )

  // 优惠券类型
  const couponTypeText = couponType
    ? translate(couponType === 5 ? 'Akulaku本金优惠' : 'Akulaku利息优惠')
    : ''

  // 商户优惠信息头部
  const renderMerchantCouponHeader = () => (
    <>
      <span className={`font-roboto-bold ${styles.left}`}>
        {translate(`xx优惠`, {
          name: merchantCouponVOS[0].merchantName || '',
        })}
      </span>
      <div className={`${styles.label} font-roboto-bold`}>
        {`-${currencyCode}${formatPrice(marchantCouponPrice)}`}
      </div>
    </>
  )
  return (
    <div className={styles.CouponCard}>
      {merchantCouponVOS ? (
        merchantCouponVOS.length > 1 ? (
          <div className={`${styles.flex} ${styles.accordionHeight}`}>
            <Accordion className={styles.row} accordion>
              <Accordion.Panel
                key={'panel'}
                header={
                  <div className={styles.row}>
                    {renderMerchantCouponHeader()}
                  </div>
                }
              >
                {merchantCouponVOS.map((merchantCoupon: any) => (
                  <div
                    key={merchantCoupon.name}
                    className={classNames(styles.row, styles.rowAc)}
                  >
                    <span className={styles.labelAc}>
                      {merchantCoupon.name}
                    </span>
                    <span
                      className={classNames(
                        styles.value,
                        styles.payAmount,
                        'font-roboto-bold',
                      )}
                    >
                      {`-${currencyCode}${formatPrice(
                        _.get(merchantCoupon, 'amount.value'),
                      )}`}
                    </span>
                  </div>
                ))}
              </Accordion.Panel>
            </Accordion>
          </div>
        ) : (
          <div className={styles.flex}>{renderMerchantCouponHeader()}</div>
        )
      ) : null}

      <div className={styles.flex}>
        <span className={`${styles.left} ${styles.nowrap}`}>
          <span className="font-roboto-bold">{translate('优惠券')}</span>
          {couponTypeText && curCoupon && (
            <span className={styles.coupon_type}>{couponTypeText}</span>
          )}
        </span>
        <div
          className={`${styles.right} font-roboto-bold`}
          onClick={showCouponList}
        >
          {renderRight()}
        </div>
      </div>
    </div>
  )
}
