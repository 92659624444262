import React, { PureComponent } from 'react'
import { Modal } from 'antd-mobile'
import { connect } from 'react-redux'

import { helpers, withBasic } from '@common'
import { Button } from '~/components/Button'
import { CouponCodeInput } from '../../../login/components/InputFields'
import Logger, * as elementId from '~/config/logger'
import { actionCreator } from '../../store'

import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

@withBasic
class PromotionCodeForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      coupon: '',
    }
    this.controls = []
    this.beforeSubmit = this.beforeSubmit.bind(this)
    this.saLogger = Logger.getInstance()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.show) {
      /*
       * 当优惠码组件隐藏后，需要手动执行input的失焦事件
       * 如果不执行的话，input就可能一直是focus的状态，在ios下软键盘会覆盖优惠码组件
       */
      this.couponInputRef &&
        this.couponInputRef.makeInputFocus &&
        this.couponInputRef.makeInputBlur()
    }
  }

  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  beforeSubmit = () => {
    const { setCouponErrorText } = this.props
    //提交表单前，每一个控件应该通过数据校验
    let result, errMsg

    result = this.controls.every((elem) => {
      if (elem.validate(elem.state.value) === false) {
        return true
      } else {
        errMsg = elem.validate(elem.state.value)
        setCouponErrorText(errMsg)
        return false
      }
    })

    return result
  }

  submit = () => {
    /*
     * 为了实现提交优惠码后，软键盘不收起，手动执行input的聚焦事件
     * 别问为什么，设计大佬要求的
     */
    this.couponInputRef.makeInputFocus && this.couponInputRef.makeInputFocus()

    const { updateOrderInfoWithCouponCode, setCouponErrorText } = this.props
    const { coupon } = this.state

    if (this.beforeSubmit()) {
      updateOrderInfoWithCouponCode(coupon)
      setCouponErrorText('')
    }

    this.saLogger.onPageClick(elementId.CLICK_SAVE_COUPON)
  }

  close = () => {
    const { toggleCouponCodeForm, setCouponCode, setCouponErrorText } =
      this.props
    toggleCouponCodeForm(false)
    setCouponErrorText('')
    setCouponCode('')
    this.removeController('coupon')
    this.saLogger.onPageClick(elementId.CLICK_CANCEL_COUPON)
  }

  onCouponInputRef = (ref) => {
    this.couponInputRef = ref
  }

  render() {
    const { show, translate, couponCodeErrText, setCouponErrorText } =
      this.props

    return (
      <Modal
        popup
        visible={show}
        animationType="slide-up"
        // afterClose={}
        {...className('coupon-form-slide-up')}
      >
        <div
          {...className('coupon-form-container', {
            hasError: !!couponCodeErrText,
          })}
        >
          <div {...className('input-group')}>
            <CouponCodeInput
              name="coupon"
              placeholder={translate('请输入优惠码')}
              onRef={this.onCouponInputRef}
              onRegister={this.register}
              onControl={this.collect}
              errText={couponCodeErrText}
              onFocus={() => {
                // setCouponErrorText('')
                this.saLogger.onPageInput(elementId.COUPON_CODE_INPUT, 'begin')
              }}
              onBlur={() => {
                this.saLogger.onPageInput(elementId.COUPON_CODE_INPUT, 'end')
              }}
              afterClear={() => setCouponErrorText('')}
              maxLength="20"
              required
            />
          </div>
          <div {...className('btn-group')}>
            <Button
              text={translate('取消')}
              size="mid"
              hollow={true}
              onClick={this.close}
            />
            <Button
              text={translate('确认')}
              size="mid"
              onClick={this.submit}
              disabled={!this.state.coupon}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  couponCodeErrText: state.getIn(['orderDetail', 'couponCodeErrText']),
  show: state.getIn(['orderDetail', 'showCouponCodeForm']),
})

const mapDispatchToProps = (dispatch) => ({
  toggleCouponCodeForm(show) {
    dispatch(actionCreator.goToggleCouponCodeForm(show))
  },
  updateOrderInfoWithCouponCode(code) {
    dispatch(
      actionCreator.goUpdateOrderPlanInfo(code, (success) => {
        success && dispatch(actionCreator.goSetCouponCode(code))
      }),
    )
    //根据该优惠码重新获取订单信息
  },
  setCouponCode(code) {
    dispatch(actionCreator.goSetCouponCode(code))
  },
  setCouponErrorText(errText) {
    dispatch(actionCreator.goSetCouponErrorText(errText))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotionCodeForm)
