import React, { useEffect } from 'react'
import { useModal, create, show } from '@ebay/nice-modal-react'
import { Modal } from 'antd-mobile'
import { Translate } from '@common/Basic/translate'
import Logger from '@/project/openPay @/config/logger'
import saConfig from './sa.config'
import styles from './style.scss'

const LicensePop = create((props) => {
  const modal = useModal()
  const { translate: t } = Translate.getInstance()
  const logger = Logger.getInstance()

  useEffect(() => {
    logger.popView(saConfig.pop_view)
  }, [])

  const onOk = () => {
    logger.popClick(saConfig.click_agree)
    modal.hide()
    props.onOk && props.onOk()
  }

  const clickServiceTerm = () => {
    props.clickServiceTerm && props.clickServiceTerm()
  }

  const clickPasswordTerm = () => {
    modal.hide()
    props.clickPasswordTerm && props.clickPasswordTerm()
  }

  return (
    <Modal
      popup
      className={styles.modal}
      visible={modal.visible}
      animationType="slide-up"
      title={<span className={styles.title}>{t('Terms of Service')}</span>}
      closable={true}
      onClose={modal.hide}
    >
      <div className={styles.content}>
        <div className={styles.text}>
          {t(
            'I have read, understood and agree to the terms and conditions of',
          )}{' '}
          <span onClick={clickServiceTerm}>
            {t('Akulaku Finance Indonesia Installment Contract')}
          </span>{' '}
          {props.isSmallPay && (
            <>
              {t('and')}{' '}
              <span onClick={clickPasswordTerm}>
                {t('password-free login policy')}
              </span>
            </>
          )}
        </div>
        <div className={styles.btns}>
          <div
            className={styles.cancel}
            onClick={() => {
              logger.popClick(saConfig.click_disagree)
              modal.hide()
            }}
          >
            {t('Disagree')}
          </div>
          <div className={styles.agree} onClick={onOk}>
            {t('Agree')}
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default {
  show(props) {
    show(LicensePop, props)
  },
}
